import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import { Hero, Products, UseCases } from '../components/Home'

const Home = props => {
  const { products, useCases } = props.data.contentJson.home

  return (
    <Layout location={props.location}>
      <div className='page-home'>
        <Helmet
          meta={[
            {
              name: 'description',
              content: 'Tierion turns the blockchain into a global platform for verifying any data, file, or business process.'
            }
          ]}
        />

        <Hero
          title='Simplify Trust'
          subtitle='Tierion creates technology and products that reduce the cost and complexity of trust.'
        />

        <Products products={products} />

        <UseCases
          featureList={useCases}
          subtitle='Common use cases'
        />

        {/* <Testimonials
          testimonials={testimonials}
          subtitle={`Some of the world's most innovative companies use our technology.`}
          title={`You’re in good company`}
        /> */}

      </div>
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        navigation {
          path
          label
          color
        }
      }
    }
    contentJson {
      home {
        products {
          title
          text
          link {
            text
            href
          }
        }
        useCases {
          icon
          title
          text
        }
        testimonials {
          image
          text
          name
          link
        }
      }
    }
  }
`
