import React from 'react'
import {Link} from 'gatsby'

import Icon from '../components/Icon'
import Carousel from '../components/Carousel'
import { importAll } from '../utils/helpers'

import {
  Section,
  SectionTitle,
  ContainedRow,
  IconList
} from './UI'

const pageImgs = importAll(require.context('../img/home', false, /\.(png|jpe?g|svg)$/))

const Hero = props => (
  <Section {...props} className='hero'>
    <SectionTitle
      {...props}
      className='hero-title col col-md-8 col-lg-7 col-xl-6'
    />

  </Section>
)

const Products = props => (
  <Section {...props} className='products'>
    <ContainedRow className='justify-content-between'>
      {props.products.map((product, index) =>
        <div className='product col col-12 col-md-5' key={index}>
          <h2>{product.title}</h2>
          <p className='paragraph'>
            {product.text}
          </p>
          <Link
            to={product.link.href}
            className='arrow-link'
          >
            {product.link.text}
          </Link>
          <img
            src={
              product.title === 'Proof'
                ? pageImgs['./home-product-proof.svg']
                : pageImgs['./home-product-chainpoint.svg']
            }
            alt={product.title}
          />
        </div>
      )}
    </ContainedRow>
  </Section>
)

const UseCases = props => (
  <Section {...props} className='usecases'>
    <SectionTitle {...props} />
    <IconList {...props} pageImgs={pageImgs} />
  </Section>
)

const Testimonials = props => (
  <Section {...props} className='testimonials'>
    <SectionTitle {...props} className='section-title-alt col' />
    <div className='carousel-container'>
      <Carousel>
        {props.testimonials.map((testimonial, index) =>
          <TestimonialItem {...testimonial} key={index} />
        )}
      </Carousel>
    </div>
  </Section>
)

const TestimonialItem = props => (
  <a href={props.link} target='_blank' rel='noopener noreferrer' className='testimonial-item'>
    <div className='testimonial-item-image'>
      <img src={pageImgs[`./${props.image}`]} alt='Testimonial' />
    </div>
    <div className='testimonial-item-text'>
      {props.text}
    </div>
    <div className='testimonial-item-name'>
      {props.name}
    </div>
    <div className='testimonial-item-title'>
      {props.title}
    </div>
  </a>
)

const Challenge = props => (
  <Section {...props}>
    <ContainedRow>
      <div className='col col-12 col-md-6 ml-md-auto'>
        <h1>{props.title}</h1>
        <p className='paragraph-large'>
          {props.subtitle}
        </p>
      </div>
      <div className='col col-12 col-md-4 ml-md-auto'>
        <Link className='btn btn-primary' to='/'>
          Learn More
          <Icon type='external-link' />
        </Link>
      </div>
    </ContainedRow>
  </Section>
)

export {
  Hero,
  Products,
  UseCases,
  Testimonials,
  Challenge
}
