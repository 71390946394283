import React from 'react'

if (typeof window !== 'undefined') {
  var Flickity = require('flickity')
  require('flickity-imagesloaded')
}

class Carousel extends React.Component {
  constructor () {
    super()

    this.state = {
      selectedIndex: 0
    }
  }

  componentDidMount () {
    const carousel = this.refs.carousel

    const winWidth = window.innerWidth

    this.setState({
      visibleSlides: winWidth > 768 && winWidth < 1200 ? 1 : winWidth >= 1200 ? 2 : 0
    })

    this.options = {
      pageDots: false,
      cellAlign: 'left',
      imagesLoaded: true,
      contain: true,
      arrowShape: 'M33.499995,50.9723684 L43.1998981,60.9722685 C43.8665581,61.5389295 45.1332121,61.7722605 45.8665381,61.1056005 C46.5998641,60.4389405 46.5665311,59.1722865 45.8332051,58.5389595 L38.99994,51.5390294 L64.4996852,51.5390294 C65.4996752,51.5390294 66.3330002,50.7390374 66.3330002,49.7723804 C66.3330002,48.8057234 65.5330082,48.0057314 64.4996852,48.0057314 L38.99994,48.0057314 L45.8332051,40.9724684 C46.4665321,40.3724744 46.5665311,39.0724874 45.8665381,38.4058274 C45.1665451,37.7391674 43.8332251,37.9724984 43.1998981,38.5391594 L33.499995,48.5390594 C33.166665,48.8723894 33,49.2723854 33,49.7390474 C33,50.1723764 33.199998,50.6723714 33.499995,50.9723684 Z',
      ...this.props.options
    }

    this.flkty = new Flickity(carousel, this.options)
    this.flkty.on('select', this.updateSelected.bind(this))
  }

  updateSelected () {
    // force flkty's selected index to be in the desired range of visible slides (based on window width)
    const index = this.state.visibleSlides + this.flkty.selectedIndex >= this.flkty.slides.length ? this.flkty.slides.length - 1 - this.state.visibleSlides : this.flkty.selectedIndex
    this.flkty.selectedIndex = index

    this.setState({
      selectedIndex: index
    })

    this.updateSlideStyle()
  }

  updateSlideStyle () {
    const slides = Array.from(
      this.refs.carousel.querySelectorAll('.testimonial-item')
    )

    slides.forEach((slide, index) => {
      if (
        slides.indexOf(slide) < this.state.selectedIndex ||
        slides.indexOf(slide) > this.state.selectedIndex + this.state.visibleSlides
      ) {
        slide.classList.add('is-overflowing')
      } else {
        slide.classList.remove('is-overflowing')
      }
    })
  }

  componentWillUnmount () {
    if (this.flkty) {
      this.flkty.off('select', this.updateSelected.bind(this))
      this.flkty.destroy()
    }
  }

  render () {
    return (
      <div className='container'>
        <div ref='carousel' className='carousel'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Carousel
